import {useState} from 'react';
import Form from 'react-bootstrap/Form';

export function FilterLineForm({defaults, handleUpdate, properties, submit}) {
    const [condition, setCondition] = useState(defaults.condition);
    const [keysNextLevel, setKeysNextLevel] = useState([]);
    const [key, setKey] = useState(defaults.key);
    const [keyNextLevel, setKeyNextLevel] = useState('');
    const [value, setValue] = useState(defaults.value);

    const update = values => {
        let localCondition;
        let localKey;
        let localKeyNextLevel;
        let localValue;

        if ('condition' in values) {
            localCondition = values.condition;
            setCondition(localCondition);
        } else {
            localCondition = condition;
        }

        if ('key' in values) {
            localKey = values.key;
            setKey(localKey);
        } else {
            localKey = key;
        }

        if ('keyNextLevel' in values) {
            localKeyNextLevel = values.keyNextLevel;
            setKeyNextLevel(localKeyNextLevel);
        } else {
            localKeyNextLevel = keyNextLevel;
        }

        if ('value' in values) {
            localValue = values.value;
            setValue(localValue);
        } else {
            localValue = value;
        }

        handleUpdate({
            'condition': localCondition,
            'key': localKey,
            'keyNextLevel': localKeyNextLevel,
            'value': localValue,
        });
    };

    return (
        <Form.Group className="mb-3">
            <Form.Select
                name="key"
                onChange={event => {
                    update({'key': event.target.value})

                    let localKeysNextLevel = [];
                    try {
                        if (event.target.value === 'connectionPoints') {
                            localKeysNextLevel = [
                                'capaciteInjectionDispo',
                                'capaciteSoutirageDispo',
                                'code',
                                'coordinates',
                                'idDepart',
                                'type',
                            ];
                        } else {
                            const nextLevelProperties = JSON.parse(properties[event.target.value]);
                            localKeysNextLevel = Object.keys(nextLevelProperties[0]).sort();
                        }
                    } catch (e) {} // do nothing

                    setKeysNextLevel(localKeysNextLevel);
                }}
                size="sm"
                value={key}
            >
                <option key="" value="" />
                {Object.keys(properties).sort().map(localKey =>
                    <option key={localKey} value={localKey}>{localKey}</option>)}
            </Form.Select>
            {keysNextLevel.length > 0 && <Form.Select className="mt-1" name="keyNextLevel" onChange={event => update({'keyNextLevel': event.target.value})} size="sm">
                <option key="" value="" />
                {keysNextLevel.map(localKey =>
                    <option key={localKey} value={localKey}>{localKey}</option>)}
            </Form.Select>}
            <Form.Select
                className="mt-1"
                name="condition"
                onChange={event => update({'condition': event.target.value})}
                size="sm"
                defaultValue={condition}
            >
                {[{
                    'key': '',
                    'name': '',
                }, {
                    'key': 'eq',
                    'name': '=',
                }, {
                    'key': 'gte',
                    'name': '>=',
                }, {
                    'key': 'lte',
                    'name': '<=',
                }, {
                    'key': 'incl',
                    'name': '⊃',
                }].map(option => <option key={option.key} value={option.key}>{option.name}</option>)}
            </Form.Select>
            <Form.Control
                className="mt-1"
                name="value"
                onChange={event => update({'value': event.target.value})}
                onKeyDown={event => {
                    if (event.key === 'Enter') {
                        submit();
                    }
                }}
                size="sm"
                type="text"
                defaultValue={value}
            />
        </Form.Group>
    );
}
