import L from 'leaflet';
import 'leaflet-draw';
import {createControlComponent} from '@react-leaflet/core';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

const GeomanControl = L.Control.extend({
    initialize(options) {
        L.PM.setOptIn(true);
        L.setOptions(this, options);
    },

    addTo(map) {
        const {globalOptions, events} = this.options;
        // const circleMaxRadius = 70;
        // const circleMinRadius = 50;

        // This should never happen, but it's better than crashing the page
        if (!map.pm) return;

        map.pm.addControls({
            'cutPolygon': false,
            'drawCircle': false,
            'drawCircleMarker': false, // same as drawCircle except that it is in pixels, i.e. remains same size independent from zoom level
            'drawMarker': false,
            'drawPolygon': true,
            'drawPolyline': false,
            'drawRectangle': false,
            'drawText': false,
            'editMode': false,
            'oneBlock': true,
            'position': 'topleft',
            'rotateMode': true,
        });

        map.pm.setGlobalOptions({
            'snappable': true,
            // 'maxRadiusCircle': circleMaxRadius,
            // 'minRadiusCircle': circleMinRadius,
            ...globalOptions,
        });

        // map.on('pm:drawstart', e => {
        //     const shape = e.shape;
        //     const circle = e.workingLayer;
        //
        //     // fix radius upon initial click
        //     circle.on('pm:centerplaced', () => {
        //         circle.setRadius(circleMinRadius);
        //         console.log(circle);
        //     });
        //
        //     // calculate and show radius upon change
        //     if (shape === 'Circle') {
        //         circle.on('pm:change', e => {
        //             const label = `${parseFloat(e.layer._mRadius).toFixed(0)}&nbsp;m`;
        //             e.target.bindTooltip(label, {permanent: true}).openTooltip();
        //         });
        //     }
        // });

        map.on('pm:create', function (e) {
            // ensure that newly created layers can be removed with Geoman's eraser
            e.layer.options.pmIgnore = false;
            L.PM.reInitLayer(e.layer);

            // calculate and show area
            const shape = e.shape;
            if (shape === 'Polygon') {
                const layer = e.layer;
                const latLngs = layer.getLatLngs();
                const area = L.GeometryUtil.geodesicArea(latLngs[0]);
                const readableArea = L.GeometryUtil.readableArea(area, true, 2);
                layer.bindTooltip(readableArea, { 'permanent': true }).openTooltip();
            }
        });
    },
});

const createControl = (props) => {
    return new GeomanControl(props);
};

export const Geoman = createControlComponent(createControl);

