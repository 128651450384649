import {useEffect, useState} from 'react';
import {renderToString} from 'react-dom/server';
import {useMap} from 'react-leaflet/hooks';
import L from 'leaflet';
import 'leaflet-easybutton/src/easy-button';
import {Bullseye as IconBullseye, Trash3Fill as IconTrash} from 'react-bootstrap-icons';

export function CustomActions() {
    const map = useMap();
    const circleFeatureGroup = L.featureGroup().addTo(map);

    const [bufferRadius, setBufferRadius] = useState(50);
    const [isActionBufferActive, setIsActionBufferActive] = useState(false);

    const createActionBuffer = () => {
        const title = 'Circle Buffer';
        const buttonBufferIcon = renderToString(<IconBullseye title={title}/>);
        const buttonBuffer = L.easyButton({
            'states': [{
                'stateName': 'button-inactive',
                'icon': buttonBufferIcon,
                'title': title,
                'onClick': btn => {
                    btn.state('button-active');
                    setIsActionBufferActive(true);
                    const container = document.getElementById('circle-buffer-action');
                    container.classList.remove('d-none');
                }
            }, {
                'stateName': 'button-active',
                'icon': buttonBufferIcon,
                'title': title,
                'onClick': btn => {
                    btn.state('button-inactive');
                    setIsActionBufferActive(false);
                    const container = document.getElementById('circle-buffer-action');
                    container.classList.add('d-none');
                }
            }],
        }).addTo(map);

        // add additional logic to button
        const buttonContainer = document.getElementsByClassName('easy-button-container')[0];
        const container = document.createElement('div');
        container.id = 'circle-buffer-action';
        container.className = 'leaflet-pm-actions-container d-none';
        const action = document.createElement('div');
        action.className = 'leaflet-pm-action';
        const a = document.createElement('a');
        a.className = 'pe-2';
        a.textContent = `Radius (m)`;
        action.appendChild(a);
        const input = document.createElement('input');
        L.DomEvent.disableClickPropagation(input);
        input.className = 'ps-1';
        input.maxLength = 4;
        input.onkeyup = () => {
            let newRadius = parseInt(input.value, 10);
            if (input.value !== '' && Number.isInteger(newRadius) === false) {
                input.value = bufferRadius;
                newRadius = bufferRadius;
            }

            setBufferRadius(newRadius);
        };
        input.type = 'text';
        input.value = bufferRadius;
        action.appendChild(input);
        container.appendChild(action);
        buttonContainer.appendChild(container);

        return buttonBuffer;
    };

    const createActionDelete = () => {
        return L.easyButton(renderToString(<IconTrash />), (btn, localMap) => {
            localMap.eachLayer(function(layer){
                if (layer._path !== null && layer.pm !== undefined) {
                    layer.remove()
                }
            });
        }, 'Remove all').addTo(map);
    };

    const drawCircle = (event, radius) => {
        L.circle(event.latlng, {
            'pmIgnore': false,
            'radius': 1,
        }).addTo(circleFeatureGroup); // this is a hack to mark the center of the circle... through another circle :/
        L.circle(event.latlng, {
            'pmIgnore': false,
            'radius': radius,
        }).addTo(circleFeatureGroup);
        circleFeatureGroup.bringToFront();
    };

    useEffect(() => {
        if (isActionBufferActive === true) {
            // @TODO: ensure that this bind is getting triggered first, so that stopPropagation can work
            map.on('click', event => {
                drawCircle(event, bufferRadius);
            });
        }

        return () => {
            map.off('click');
        };
    }, [bufferRadius, isActionBufferActive]);

    useEffect(() => {
        const actionBuffer = createActionBuffer();
        const actionDelete = createActionDelete();

        return () => {
            actionBuffer.remove();
            actionDelete.remove();
        };
    }, []);
}
