import {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import Form from 'react-bootstrap/Form';
import {IconEdit, IconSave, IconTrash} from '.';
import {setSelection as setUserSelection} from '../redux/userSlice';
import {alertService, operatorService, projectService} from '../services';

export function Project({project, removePlot, setProject, setShow, show}) {
    const dispatch = useDispatch();
    const layersIds = useSelector(state => state.layers.value.ids);
    const userSelection = useSelector(state => state.user.value.selection);

    const nameNew = 'NEW_PROJECT';
    const submitLabelNew = 'Create Project';
    const submitLabelUpdate = 'Update';

    const [isEditName, setIsEditName] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [name, setName] = useState(nameNew);
    const [submitLabel, setSubmitLabel] = useState(submitLabelNew);

    // init existing project that has been selected through search
    useEffect(() => {
        if (project !== null && project.name !== undefined) {
            setName(project.name);
            setSubmitLabel(submitLabelUpdate);
        } else {
            setName(nameNew);
            setSubmitLabel(submitLabelNew);
        }

        return () => {
            setName(nameNew);
            setSubmitLabel(submitLabelNew);
        };
    }, [project]);

    // set substation code for new projects based on current selection (works for substations and substation zones)
    useEffect(() => {
        if (project === null && userSelection !== null && userSelection.type === 'Substation') {
            setProject({
                'plot_ids': [],
                'substation_code': userSelection.metadata.code,
            });
        }
    }, [userSelection]);

    const close = () => {
        setShow(false);
        setProject(null);
    };

    const handleRename = (e) => {
        setName(e.target.value);
        setProject({
            ...project,
            'name': e.target.value,
        });
    };

    const renderEditNameIcon = () => {
        if (isEditName === false) {
            return <IconEdit title="Edit name" />;
        }

        return <IconSave title="Save name" />;
    };

    const showPlot = plot => {
        const plotLayerName = 'French Plots v2';
        operatorService
            .find(layersIds[plotLayerName], {
                'field': 'display_name',
                'value': plot,
            })
            .then(item => {
                if (item !== null) {
                    dispatch(setUserSelection({
                        '_id': plot,
                        'data': {
                            'geometry': item.geometry.coordinates[0],
                        },
                        'display_name': item.display_name,
                        'metadata': item.metadata,
                        'type': 'Plot',
                    }));

                    alertService.success(`Plot <strong>${plot}</strong> selected`);
                } else {
                    alertService.warn(`Plot <strong>${plot}</strong> not found on <strong>${plotLayerName}</strong>`);
                }
            });
    };

    if (show === true) {
        const plotsCount = project?.plot_ids ? project.plot_ids.length : 0;
        return (
            <div className="modal" id="project">
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        setIsSubmitting(true);

                        // update
                        // if (project !== null && project.name !== undefined) {
                        //     // && project.id !== undefined) {
                        //     setIsSubmitting(false);
                        //     alertService.warn("PROJECT UPDATE TEMPORARILY DISABLED");
                        //     return;

                        //     // keep in mind that the substation cannot be changed
                        //     projectService
                        //         .put(project.name, {
                        //             name: name,
                        //             plot_ids: project.plot_ids,
                        //         })
                        //         .then(() => {
                        //             alertService.success(`Project <strong>${name}</strong> successfully updated.`);
                        //         })
                        //         .catch(() => {
                        //             alertService.error(`Update of project <strong>${name}</strong> failed.`);
                        //         })
                        //         .finally(() => {
                        //             setIsSubmitting(false);
                        //         });
                        //     // create
                        // } else

                        // {
                        // This is crucial, since no substation code will make Airtable associate ALL substations to a newly created project and cause in return a massive load time on the frontend
                        if (project.substation_code === null) {
                            alertService.warn("No substation has been selected to associate the project with");
                            setIsSubmitting(false);
                            return;
                        }

                        projectService
                            .post({
                                'name': name,
                                'plot_ids': project.plot_ids,
                                'substation_code': project.substation_code,
                            })
                            .then((response) => {
                                setProject(response);
                                alertService.success(`Project <strong>${name}</strong> successfully created.`);
                            })
                            .catch(() => {
                                alertService.error(`Creation of project <strong>${name}</strong> failed.`);
                            })
                            .finally(() => {
                                setIsSubmitting(false);
                            });
                        // }
                    }}
                >
                    <Modal.Dialog>
                        <Modal.Header closeButton={true} onHide={close}>
                            <div className="input-group me-3">
                                <Form.Control
                                    className="form-control-lg"
                                    disabled={isEditName === false}
                                    name="name"
                                    onChange={handleRename}
                                    onKeyPress={(event) => {
                                        if (event.key === "Enter") {
                                            event.preventDefault();
                                            setIsEditName(false);
                                        }
                                    }}
                                    type="text"
                                    value={name}
                                />
                                <span
                                    className="input-group-text"
                                    onClick={() => {
                                        setIsEditName(!isEditName);
                                    }}
                                >
                                    {renderEditNameIcon()}
                                </span>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <h6>
                                Substation{" "}
                                {project === null || project.substation_code === null ? (
                                    <span className="badge bg-warning">NONE</span>
                                ) : (
                                    <span className="badge bg-secondary">{project.substation_code}</span>
                                )}
                            </h6>
                            <h6>
                                Selected plots <span className="badge bg-secondary">{plotsCount}</span>
                            </h6>
                            {plotsCount > 0 && (
                                <ul className="mb-0">
                                    {project.plot_ids.map((plotId, key) => (
                                        <li key={key}>
                                            <a
                                                href=""
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    showPlot(plotId);
                                                }}
                                            >
                                                {plotId}
                                            </a>
                                            {/*<a href="" onClick={e => {*/}
                                            {/*    e.preventDefault();*/}
                                            {/*    removePlot(plotId);*/}
                                            {/*}}><IconTrash /></a>*/}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Form.Control disabled={project === null || isSubmitting} className="btn btn-primary" type="submit" value={submitLabel} />
                        </Modal.Footer>
                    </Modal.Dialog>
                </Form>
            </div>
        );
    }
}
