import {fetchWithMsal, handleResponse} from "../utils";

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/project/`;

export const projectService = {
    post,
    put,
};

function post(project) {
    return fetchWithMsal(`${baseUrl}`, {
        method: "POST",
        body: JSON.stringify(project),
    })
        .then(handleResponse)
        .catch((error) => {
            console.error(`projectService.post: Project: ${JSON.stringify(project)}`, error);
            throw new Error(error); // pass error along
        });
}

function put(id, project) {
    return fetchWithMsal(`${baseUrl}/${id}`, {
        body: JSON.stringify(project),
        method: "PUT",
    })
        .then(handleResponse)
        .catch((error) => {
            console.error(`projectService.post: Project: ${JSON.stringify(project)}`, error);
            throw new Error(error); // pass error along
        });
}
