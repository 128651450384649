import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import L from "leaflet";
import Form from "react-bootstrap/Form";
import debounce from "debounce";
import {
  Arrow90degRight as IconArrowProject,
  ArrowRepeat as IconArrowRepeat,
  Filter as IconFilter,
  Person as IconPerson,
} from "react-bootstrap-icons";
import {
  setSelection as setUserSelection,
  setSubstation as setUserSubstation,
} from "../redux/userSlice";
import { operatorService } from "../services";
import { getCoordinatesForGeometry, getLatLng } from "../utils";

export function Search({
  filtersShow,
  isLoading,
  profileShow,
  projectShow,
  searchInput,
  setFiltersShow,
  setIsLoading,
  setProfileShow,
  setProjectShow,
  setSearchInput,
}) {
  const dispatch = useDispatch();
  const layersIds = useSelector((state) => state.layers.value.ids);
  const userSelection = useSelector((state) => state.user.value.selection);

  const [searchResultsCoordinates, setSearchResultsCoordinates] =
    useState(null);
  const [searchResultsSubstation, setSearchResultsSubstation] = useState([]);
  const [searchResultsMunicipality, setSearchResultsMunicipality] = useState(
    []
  );
  const [searchResultsPlot, setSearchResultsPlot] = useState([]);
  const [searchResultsProject, setSearchResultsProject] = useState([]);
  const [suggestionsShow, setSuggestionsShow] = useState(false);

  useEffect(() => {
    // stop event propagation to map for interactions with the search component
    const div = document.getElementById("search");
    L.DomEvent.disableClickPropagation(div);

    // close suggestions on escape
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setFiltersShow(false);
        setSuggestionsShow(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      setSuggestionsShow(false);
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event) => {
    let input = event.target.value;

    // update search input value
    setSearchInput(input);
    // close list of suggestions
    setSuggestionsShow(false);

    // ignore if too short
    if (input.length < 2) {
      return;
    }

    input = input.trim();

    // check whether input is a GPS coordinate
    setSearchResultsCoordinates(null);
    const coordinates = getLatLng(input);
    if (coordinates !== null) {
      // show entered coordinates as a clickable result
      setSearchResultsCoordinates({
        _id: input,
        data: {
          latitude: coordinates[0],
          longitude: coordinates[1],
        },
        display_name: input,
        search_value: input,
        type: "Coordinates",
      });

      setSuggestionsShow(true);
      setFiltersShow(false);

      // show nearby substations, if any
      operatorService.near(layersIds["PS v2"], coordinates).then((items) => {
        setSearchResultsSubstation(
          items.map((item) => ({
            _id: item._id,
            code: item.metadata.id_PS,
            display_name: item.display_name,
            geometry: item.geometry,
            metadata: item.metadata,
            search_value: item.display_name,
            type: "Substation",
          }))
        );
      });
    } else {
      debounce(() => {
        // search by substation
        setSearchResultsSubstation([]);
        operatorService
          .autocomplete(
            layersIds["PS v2"],
            ["display_name", "metadata.code"],
            input,
            5
          )
          .then((items) => {
            setSearchResultsSubstation(
              items.map((item) => {
                let code = "";
                if (item.metadata.code !== undefined) {
                  code = ` ${item.metadata.code}`;
                }

                return {
                  _id: item._id,
                  code: item.metadata.code,
                  display_name: item.display_name,
                  geometry: item.geometry,
                  metadata: item.metadata,
                  search_value: `${item.display_name}${code}`,
                  type: "Substation",
                };
              })
            );

            if (items.length > 0) {
              setSuggestionsShow(true);
            }
          })
          .finally(() => {
            setFiltersShow(false);
          });
        // search by municipalities
        setSearchResultsMunicipality([]);
        operatorService
          .autocomplete(
            layersIds["Municipalities France"],
            ["display_name", "metadata.code", "metadata.codesPostaux"],
            input,
            5
          )
          .then((items) => {
            const results = items.map((item) => {
              let codeInsee = "";
              if (item.metadata.code !== undefined) {
                codeInsee = ` ${item.metadata.code}`;
              }

              return {
                _id: item._id,
                data: {
                  coordinates: getCoordinatesForGeometry(item.geometry),
                },
                display_name: item.display_name,
                search_value: `${item.display_name}${codeInsee}`,
                type: "Municipality",
              };
            });
            setSearchResultsMunicipality(results);

            if (items.length > 0) {
              setSuggestionsShow(true);
            }
          })
          .finally(() => {
            setFiltersShow(false);
          });
        // search by plot ID
        setSearchResultsPlot([]);
        operatorService
          .autocomplete(
            layersIds["French Plots v2"],
            ["display_name"],
            input,
            5
          )
          .then((items) => {
            setSearchResultsPlot(
              items.map((item) => {
                return {
                  _id: item._id,
                  data: {
                    geometry: item.geometry.coordinates[0],
                  },
                  display_name: item.display_name,
                  metadata: item.metadata,
                  search_value: item.display_name,
                  type: "Plot",
                };
              })
            );

            if (0 < items.length) {
              setSuggestionsShow(true);
            }
          })
          .finally(() => {
            setFiltersShow(false);
          });
        // search by project
        setSearchResultsProject([]);
        operatorService
          .autocomplete(layersIds["Projects"], ["display_name"], input, 5)
          .then((items) => {
            setSearchResultsProject(
              items.map((item) => ({
                _id: item._id,
                data: {
                  geometry: item.geometry,
                },
                display_name: item.display_name,
                metadata: item.metadata,
                search_value: item.display_name,
                type: "Project",
              }))
            );

            if (0 < items.length) {
              setSuggestionsShow(true);
            }
          })
          .finally(() => {
            setFiltersShow(false);
          });
      }, 300)();
    }
  };

  const selectResult = (newSelection) => {
    setIsLoading(true);
    setSearchInput(newSelection.search_value);
    setSuggestionsShow(false);
    dispatch(setUserSelection(newSelection));
    dispatch(setUserSubstation(null));
  };

  const toggleFiltersShow = () => {
    setFiltersShow(!filtersShow);
    setProfileShow(false);
    setSuggestionsShow(false);
  };

  const toggleProfileShow = () => {
    setProfileShow(!profileShow);
    setFiltersShow(false);
    setSuggestionsShow(false);
  };

  const toggleProjectShow = () => {
    setProjectShow(!projectShow);
  };

  return (
    <div id="search">
      <div className="input-group mb-1">
        <Form.Control
          className="form-control-lg"
          onChange={handleSearch}
          type="text"
          value={searchInput}
        />
        {userSelection !== null && (
          <span
            className={`input-group-text p-1`}
            onClick={() => {
              dispatch(
                setUserSelection({
                  ...userSelection,
                })
              );
            }}
            title="Refresh"
          >
            <IconArrowRepeat size={24} />
          </span>
        )}
        <span
          className={`input-group-text p-1${filtersShow ? " active" : ""}`}
          onClick={toggleFiltersShow}
          title="Toggle filter view"
        >
          <IconFilter size={24} />
        </span>
        <span
          className={`input-group-text p-1${projectShow ? " active" : ""}`}
          onClick={toggleProjectShow}
          title="Toggle project view"
        >
          <IconArrowProject size={24} />
        </span>
        <span
          className={`input-group-text p-1${profileShow ? " active" : ""}`}
          onClick={toggleProfileShow}
          title="Toggle profile view"
        >
          <IconPerson size={24} />
        </span>
      </div>
      {isLoading === true && (
        <div className="search-information">
          <ul>
            <li className="fst-italic no-results p-2">Loading...</li>
          </ul>
        </div>
      )}
      {suggestionsShow === true && (
        <div className="search-information">
          {searchResultsCoordinates === null &&
            searchResultsSubstation.length === 0 &&
            searchResultsMunicipality.length === 0 &&
            searchResultsPlot.length === 0 &&
            searchResultsProject.length === 0 && (
              <ul>
                <li className="fst-italic no-results p-2">No results</li>
              </ul>
            )}
          {searchResultsCoordinates !== null && (
            <>
              <div className="p-2 pb-0 fst-italic">Coordinates</div>
              <ul>
                <li onClick={() => selectResult(searchResultsCoordinates)}>
                  Latitude: {searchResultsCoordinates.data.latitude}, Longitude:{" "}
                  {searchResultsCoordinates.data.longitude}
                </li>
              </ul>
            </>
          )}
          {searchResultsSubstation.length > 0 && (
            <>
              <div className="p-2 pb-0 fst-italic">Substations</div>
              <ul>
                {searchResultsSubstation.map((result, key) => (
                  <li key={key} onClick={() => selectResult(result)}>
                    {result.search_value.toUpperCase()}
                  </li>
                ))}
              </ul>
            </>
          )}
          {searchResultsMunicipality.length > 0 && (
            <>
              <div className="p-2 pb-0 fst-italic">Municipalities</div>
              <ul>
                {searchResultsMunicipality.map((result, key) => (
                  <li key={key} onClick={() => selectResult(result)}>
                    {result.search_value.toUpperCase()}
                  </li>
                ))}
              </ul>
            </>
          )}
          {searchResultsPlot.length > 0 && (
            <>
              <div className="p-2 pb-0 fst-italic">Plots</div>
              <ul>
                {searchResultsPlot.map((result, key) => (
                  <li key={key} onClick={() => selectResult(result)}>
                    {result.search_value.toUpperCase()}
                  </li>
                ))}
              </ul>
            </>
          )}
          {searchResultsProject.length > 0 && (
            <>
              <div className="p-2 pb-0 fst-italic">Projects</div>
              <ul>
                {searchResultsProject.map((result, key) => (
                  <li key={key} onClick={() => selectResult(result)}>
                    {result.search_value.toUpperCase()}
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      )}
    </div>
  );
}
