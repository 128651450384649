import {fetchWithMsal, handleResponse} from '../utils';

export const elevationService = {
    get,
};

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/elevation`;

function get(coordinates) {
    const pathElements = [];
    coordinates.forEach(coordinate => {
        pathElements.push(`${coordinate.lat},${coordinate.lng}`);
    });

    if (pathElements.length > 512) {
        console.error(`elevationService.get: you requested elevation for ${pathElements.length} coordinates but only 512 are allowed at most.`);
        return Promise.resolve(null);
    }

    const locations = pathElements.join('|');
    return fetchWithMsal(`${baseUrl}/${locations}`, {
        method: 'GET',
    })
        .then(handleResponse)
        .then(response => {
            if (response.status !== 'OK') {
                console.error(`elevationService.get: ${response.status}`, response.error_message);
                return null;
            }

            return response.results;
        })
        .catch(error => {
            console.error('elevationService.get', error, locations);
            return null;
        });
}
