import {createSlice} from '@reduxjs/toolkit'

const DEFAULT_COUNTRY = process.env.REACT_APP_DEFAULT_COUNTRY || 'FR';

export const countrySlice = createSlice({
    'name': 'country',
    'initialState': {
        'value': {
            'current': DEFAULT_COUNTRY,
            'list': [DEFAULT_COUNTRY],
        },
    },
    'reducers': {
        'reset': state => {
            state.value.current = DEFAULT_COUNTRY;
            state.value.list = [DEFAULT_COUNTRY];
        },
        'setCurrent': (state, action) => {
            state.value.current = action.payload;
        },
        'setList': (state, action) => {
            state.value.list = action.payload;
        },
    },
});

export const {reset, setCurrent, setList} = countrySlice.actions;

export default countrySlice.reducer;
