import {createSlice} from '@reduxjs/toolkit'
import {availableModes} from '../utils';

export const userSlice = createSlice({
    'name': 'user',
    'initialState': {
        'value': {
            'layers': null,
            'mode': Object.keys(availableModes)[0],
            'selection': null,
            'substation': null,
        },
    },
    'reducers': {
        'setLayers': (state, action) => {
            state.value.layers = action.payload;
        },
        'setMode': (state, action) => {
            state.value.mode = action.payload;
        },
        'setSelection': (state, action) => {
            state.value.selection = action.payload;
        },
        'setSubstation': (state, action) => {
            state.value.substation = action.payload;
        },
    },
});

export const {setLayers, setMode, setSelection, setSubstation} = userSlice.actions;

export default userSlice.reducer;
