import L from 'leaflet';

export const BoxSelect = L.Handler.extend({
    initialize(map) {
        this._map = map;
        this._resetStateTimeout = 0;
        this._container = map._container;
        this._pane = map._panes.overlayPane;
        map.on('unload', this._destroy, this);
    },

    addHooks() {
        L.DomEvent.on(this._container, 'pointerdown', this._onPointerDown, this);
    },

    removeHooks() {
        L.DomEvent.off(this._container, 'pointerdown', this._onPointerDown, this);
    },

    moved() {
        return this._moved;
    },

    _destroy() {
        this._pane.remove();
        delete this._pane;
    },

    _resetState() {
        this._resetStateTimeout = 0;
        this._moved = false;
    },

    _clearDeferredResetState() {
        if (this._resetStateTimeout !== 0) {
            clearTimeout(this._resetStateTimeout);
            this._resetStateTimeout = 0;
        }
    },

    _onPointerDown(e) {
        if (!e.shiftKey || (e.button !== 0)) { return false; }

        // Clear the deferred resetState if it hasn't executed yet, otherwise it
        // will interrupt the interaction and orphan a box element in the container.
        this._clearDeferredResetState();
        this._resetState();

        L.DomUtil.disableTextSelection();
        L.DomUtil.disableImageDrag();

        this._startPoint = this._map.mouseEventToContainerPoint(e);

        L.DomEvent.on(document, {
            contextmenu: L.DomEvent.stop,
            pointermove: this._onPointerMove,
            pointerup: this._onPointerUp,
            keydown: this._onKeyDown
        }, this);
    },

    _onPointerMove(e) {
        if (!this._moved) {
            this._moved = true;

            this._box = L.DomUtil.create('div', 'leaflet-zoom-box', this._container);
            this._container.classList.add('leaflet-crosshair');

            this._map.fire('boxselectstart');
        }

        this._point = this._map.mouseEventToContainerPoint(e);

        const bounds = new L.Bounds(this._point, this._startPoint),
            size = bounds.getSize();

        L.DomUtil.setPosition(this._box, bounds.min);

        this._box.style.width = `${size.x}px`;
        this._box.style.height = `${size.y}px`;
    },

    _finish() {
        if (this._moved) {
            this._box.remove();
            this._container.classList.remove('leaflet-crosshair');
        }

        L.DomUtil.enableTextSelection();
        L.DomUtil.enableImageDrag();

        L.DomEvent.off(document, {
            contextmenu: L.DomEvent.stop,
            pointermove: this._onPointerMove,
            pointerup: this._onPointerUp,
            keydown: this._onKeyDown
        }, this);
    },

    _onPointerUp(e) {
        if (e.button !== 0) { return; }

        this._finish();

        if (!this._moved) { return; }
        // Postpone to next JS tick so internal click event handling
        // still see it as "moved".
        this._clearDeferredResetState();
        this._resetStateTimeout = setTimeout(this._resetState.bind(this), 0);

        const bounds = new L.LatLngBounds(
            this._map.containerPointToLatLng(this._startPoint),
            this._map.containerPointToLatLng(this._point));

        this._map
            .fire('boxselectend', { boxSelectBounds: bounds });
    },

    _onKeyDown(e) {
        if (e.code === 'Escape') {
            this._finish();
            this._clearDeferredResetState();
            this._resetState();
        }
    }
});

// @section Handlers
// @property boxSelect: Handler
// Box (shift-drag with pointer) select handler.
L.Map.addInitHook('addHandler', 'boxSelect', BoxSelect);
