export const MODE_DEFAULT = 'DEFAULT';
export const MODE_GRAND_COMPTE = 'GRAND_COMPTE';
export const MODE_PROSPECTION = 'PROSPECTION';
export const MODE_VALIDATION = 'VALIDATION';

export const availableModes = {
    [MODE_DEFAULT]: {
        'activeLayers': {
            'FR': [
                'PS v2',
                'Lignes HTA (extended)',
                'Municipalities France',
                'Parcelles Brute Force - F3',
            ],
        },
        'label': '',
    },
    [MODE_GRAND_COMPTE]: {
        'activeLayers': [
            'Lignes HTA (extended)',
            'Municipalities France',
            'PS v2',
        ],
        'label': 'FR - Grand Compte',
    },
    [MODE_PROSPECTION]: {
        'activeLayers': {
            'FR': [
                'JBox and IECharge',
                'Lignes HTA (extended)',
                'Municipalities France',
                'Projects Plot',
                'PS v2',
            ],
        },
        'label': 'FR - Recherche UF',
    },
    [MODE_VALIDATION]: {
        'activeLayers': [
        ],
        'label': 'FR - Validation',
    },
};
