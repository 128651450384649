import {renderToString} from 'react-dom/server';
import L from 'leaflet';
import {JsonView, allExpanded, defaultStyles} from 'react-json-view-lite';
import {alertService, operatorService} from '../services';
import 'react-json-view-lite/dist/index.css';

export function copyToClipboard(value, onSuccess = null) {
    navigator.clipboard
        .writeText(value)
        .then(() => {
            alertService.success(`<strong>${value}</strong> copied to clipboard.`);
            if (onSuccess !== null) {
                onSuccess();
            }
        })
        .catch(() => {
            alertService.error('Something went wrong with copying data to clipboard');
        });
}

export function createMarkerPopup(marker, setUserSelection) {
    const coordinates = marker.getLatLng();
    const coordinatesString = `${coordinates.lat},${coordinates.lng}`;

    const elementUl = document.createElement('ul');
    elementUl.setAttribute('class', 'list-unstyled');

    // search nearby
    const elementLiSearch = document.createElement('li');
    const elementLiSearchLink = document.createElement('a');
    elementLiSearchLink.setAttribute('href', '');
    elementLiSearchLink.textContent = 'Search around here';
    elementLiSearchLink.onclick = e => {
        e.preventDefault();
        setUserSelection({
            'data': {
                'latitude': coordinates.lat,
                'longitude': coordinates.lng,
            },
            'id': coordinatesString,
            'display_name': coordinatesString,
            'type': 'Coordinates',
        });
        marker.closePopup();
    };
    elementLiSearch.appendChild(elementLiSearchLink);
    elementUl.appendChild(elementLiSearch);

    // launch Google Maps
    const elementLiGmaps = document.createElement('li');
    const elementLiGmapsLink = document.createElement('a');
    elementLiGmapsLink.setAttribute('href', `https://www.google.com/maps/search/?api=1&query=${coordinatesString}`);
    elementLiGmapsLink.setAttribute('target', '_blank');
    elementLiGmapsLink.textContent = 'Open in Google Maps';
    elementLiGmaps.appendChild(elementLiGmapsLink);
    elementUl.appendChild(elementLiGmaps);

    return elementUl;
}

export function createMetadataPopup(title, data) {
    return L.popup({
        'maxHeight': 250,
        'maxWidth': 300,
    })
        .setContent(getMetadataPopup(title, data));
}

export function filter(formValues, data) {
    let filteredData = data;
    Object.keys(formValues).forEach(localFormValueKey => {
        const formValue = formValues[localFormValueKey];
        // identify the right column, either a first or a second level property key
        const isNextLevel = formValue.keyNextLevel !== '';
        filteredData = filteredData.filter(data => {
            // hit the right data based on the selected level
            if (isNextLevel === true) {
                // this is made for data arrays like "connectionPoints"
                const connectionPoints = JSON.parse(data.properties[formValue.key]);
                const matchedConnectionPoints = connectionPoints.filter(connectionPoint => {
                    return filterCheck(formValue.condition, connectionPoint[formValue.keyNextLevel], formValue.value);
                });

                return matchedConnectionPoints.length > 0;
            } else {
                return filterCheck(formValue.condition, data.properties[formValue.key], formValue.value);
            }
        });
    });

    return filteredData;
}

export function filterCheck(condition, data, value) {
    // e.g. codesPostaux is a one-element array
    if (Array.isArray(data) === true) {
        data = data.pop();
    }

    switch (condition) {
        default:
        case 'eq':
            return data == value; // it is important to not type-compare since int, float, string can be compared
        case 'gte':
            return data >= value;
        case 'lte':
            return data <= value;
        case 'incl':
            return data !== undefined && data.includes(value) === true;
    }
}

// returns the average latitude and longitude for an array of coordinates
export function getCenter(coordinates) {
    const [y, x] = coordinates.reduce((sum, coord) => {
        sum[0] += parseFloat(coord[0]);
        sum[1] += parseFloat(coord[1]);
        return sum;
    }, [0, 0]);

    return [y / coordinates.length, x / coordinates.length];
}

// get array of coordinates from geometry, the one and only for polygons or the one with the most elements for MultiPolygon
export function getCoordinatesForGeometry(geometry) {
    if (geometry.type === 'MultiPolygon') {
        let maxElementsCount = -1;
        let maxElementsKey;
        for (let i = 0; i < geometry.coordinates.length; i++) {
            const elementsCount = geometry.coordinates[i]?.[0].length;
            if (maxElementsCount < elementsCount) {
                maxElementsCount = elementsCount;
                maxElementsKey = i;
            }
        }
        return geometry.coordinates[maxElementsKey]?.[0];
    }

    return geometry.coordinates[0];
}

export function getDynamicStyles(fieldMapping, dynamicStyles, key, properties) {
    const dynamicKey = fieldMapping !== undefined && key in fieldMapping ? fieldMapping[key] : null;
    const value = properties !== undefined && dynamicKey in properties ? properties[dynamicKey] : null;
    if (
        value !== null && // we have a value that requires mapping
        dynamicStyles !== null && // we have a style definition
        key in dynamicStyles && // we have a style definition for our key
        value in dynamicStyles[key] // we have a style for our value
    ) {
        return dynamicStyles[key][value];
    }

    return null;
}

export function getDynamicStylesWithCondition(dynamicStyles, value) {
    // get value based on condition
    const condition = 'condition' in dynamicStyles ? dynamicStyles['condition'] : null;
    let values = 'values' in dynamicStyles ? dynamicStyles['values'] : {};

    // order values based on condition, to ensure applying them correctly
    let valuesKeys = Object.keys(values).map(value => parseFloat(value)).sort();
    if (condition === 'lte') {
        valuesKeys.reverse(); // lowest value needs to be checked first for <=
    }

    // loop through given values from dynamicStyles and attempt to match the condition
    for (const valuesKey of valuesKeys) {
        switch (condition) {
            case 'eq':
                if (value == valuesKey) {
                    return values[valuesKey];
                }
                break;
            case 'gte':
                if (value >= valuesKey) {
                    // console.log('we have a match', valuesKey, values[valuesKey]);
                    return values[valuesKey];
                }
                break;
            case 'lte':
                if (value <= valuesKey) {
                    return values[valuesKey];
                }
                break;
            case 'incl':
                if (value.includes(valuesKey) === true) {
                    return values[valuesKey];
                }
                break;
        }
    }

    return null;
}

export function getGeoJsonFromIntersect(layerId, elementId) {
    return operatorService.intersect(layerId, elementId)
        .then(items => {
            const geoJson = [];
            items.forEach(item => {
                geoJson.push({
                    'geometry': item.geometry,
                    'id': item._id,
                    'properties': {
                        'display_name': item.display_name, // @TODO: API should send this in metadata
                        ...item.metadata,
                    },
                    'type': 'Feature',
                });
            });

            return geoJson;
        });
}

export function getLatLng(string, separator = ',') {
    let coordinates = string.split(separator).map(coordinate => {
        return parseFloat(coordinate.trim());
    });

    // didn't find comma-separated coordinates, try semicolon
    if (coordinates.length !== 2 || coordinates.includes(NaN)) {
        if (separator !== ';') {
            return getLatLng(string, ';');
        }

        return null;
    }

    return coordinates;
}

export function getMetadataPopup(title, data) {
    return renderToString(
        <div>
            <h5>{title}</h5>
            <pre>{jsonPrettify(data)}</pre>
        </div>
    );
}

// get dynamic style for feature
export function getStyle(layer, feature) {
    let color = null;
    let fillColor = null;
    let fillOpacity = null;
    let weight = null;

    // @TODO: understand why layer.style is undefined every now and then

    // if a layer has default styles, move away from legacy and attempt to style dynamically
    if (layer.style.default !== undefined) {
        color = layer.style.default.color;
        fillColor = layer.style.default.fillColor;
        fillOpacity = layer.style.default.fillOpacity;
        weight = layer.style.default.weight;
    }

    switch (layer.type) {
        case 'brute-force':
            // only deal with layers that come with a customization
            if (layer.style.dynamic !== undefined && 'rating' in layer.style.dynamic) {
                // we need this custom code since we have ratings on a substation-level, get the highest substation rating
                const substations = 'substations' in feature.properties ? feature.properties['substations'] : [];
                let rating = 0;

                substations.forEach(substation => {
                    if ('NUM_rating' in substation && substation['NUM_rating'] > rating) {
                        rating = substation['NUM_rating'];
                    }
                });

                const styles = getDynamicStylesWithCondition(layer.style.dynamic['rating'], rating);
                if (styles !== null) {
                    color = styles.color;
                }
            }

            break;

        case 'high-voltage-lines':
            const capacity = 'capaciteInjectionDispo' in feature.properties ? Math.floor(feature.properties.capaciteInjectionDispo * 10) : null;
            const isUnderground = 'type' in feature.properties ? feature.properties.type === 'ground' : false;

            // default to 2, see Map::getLayerSettings
            if (capacity >= 20) {
                weight = 3;
            } else if (capacity >= 15) {
                weight = 2;
            } else {
                weight = 1;
            }

            color = isUnderground === true ? "#B941FF" : "#6FA8DC";
            break;

        case 'project':
            // layer has no default colors, use legacy fallback
            if (color === null) {
                color = fillColor = feature.properties['Validation.Status'] === 'KO' ? '#FF2F00' : '#4E9B47';
            } else {
                const dynamicStyles = getDynamicStyles(layer.fieldMapping, layer.style.dynamic, 'validationStatus', feature.properties);
                if (dynamicStyles !== null) {
                    if ('fillColor' in dynamicStyles) {
                        color = fillColor = dynamicStyles.fillColor;
                    }
                }
            }

            break;

        case 'road':
            const importance = "nombre_de_voies" in feature.properties ? feature.properties.nombre_de_voies : null;

            switch (importance) {
                case 4:
                case 3:
                    color = "#BBD18B";
                    weight = 4;
                    break;
                case 2:
                    color = "#FFF5D1";
                    weight = 2;
                    break;
                default:
                case 1:
                    color = "#FF97AF";
                    weight = 1;
                    break;
            }

            break;

        case 'substation':
            // layer has no default colors, use legacy fallback
            if (color === null) {
                color = fillColor = feature.properties['status'] === 'OK' ? '#4EFF00' : '#F52789';
            } else {
                const dynamicStyles = getDynamicStyles(layer.fieldMapping, layer.style.dynamic, 'status', feature.properties);
                if (dynamicStyles !== null) {
                    if ('color' in dynamicStyles) {
                        color = dynamicStyles.color;
                    }
                    if ('fillColor' in dynamicStyles) {
                        fillColor = dynamicStyles.fillColor;
                    }
                }
            }

            return {
                ...layer.style.default,
                'color': color,
                'fillColor': fillColor,
                'radius': 5,
            };

        case 'urbanism':
            const featureType = "typezone" in feature.properties ? feature.properties.typezone.toUpperCase() : null;
            switch (featureType) {
                default:
                case "A":
                case "AH":
                    color = "#FFFF4D";
                    break;
                case "N":
                case "NH":
                    color = "#82B83C";
                    break;
                case "AUC":
                case "AUS":
                case "U":
                    color = "#E96560";
                    break;
            }
            break;

        default:
            break;
    }

    return {
        ...layer.style.default,
        'color': color,
        'fillColor': fillColor,
        'fillOpacity': fillOpacity,
        'weight': weight,
    };
}

export function inverseCoordinates(coordinates) {
    return coordinates.map(coordinate => [coordinate[1], coordinate[0]]);
}

export function jsonPrettify(json) {
    return <JsonView data={json} shouldExpandNode={allExpanded} style={defaultStyles} />;
}

export function numberPrettify(number) {
    return new Intl.NumberFormat('fr-FR').format(number);
}
