import {useEffect, useState} from 'react';
import {useMap} from 'react-leaflet/hooks';
import {Button, Dropdown, DropdownButton, Form} from 'react-bootstrap';
import {
    ArrowBarUp as IconArrowBarUp,
    Eye as IconEye,
    EyeSlash as IconEyeSlash,
    Filter as IconFilter
} from 'react-bootstrap-icons';
import {FilterLineForm} from '.';
import {filter} from '../utils';

export function FilterLine({layer, layerRef, resetDataLayer, updateDataLayer}) {
    const map = useMap();
    const defaultFormValues = {
        0: layer.defaults,
    };

    const [filterKey, setFilterKey] = useState(0); // key of a list of form values
    const [formValues, setFormValues] = useState(defaultFormValues);
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(layer.isFilterOpen);

    useEffect(() => {
        let isValidCount = 0;
        Object.keys(formValues).forEach(key  => {
            if (isFilterValid(formValues[key]) === true) {
                isValidCount++;
            }
        });

        // enable filter button only when all forms are valid
        setIsLoading(isValidCount !== Object.keys(formValues).length);

        return () => {
            setIsLoading(true);
        };
    }, [formValues]);

    const formSubmit = () => {
        setIsLoading(true);

        updateDataLayer({
            'layer': {
                ...layer,
                'data': filter(formValues, layer.data),
            },
            'layerRef': layerRef,
        });

        setIsLoading(false);
    };

    const isFilterValid = values => {
        return values.condition !== '' && values.key !== '' && values.value !== '';
    };

    if (layerRef !== null) {
        return (
            <li className="list-group-item" key={`${layer._id}-${filterKey}`}>
                <div className="d-flex justify-content-between align-items-center">
                    <span>
                        {layer.name} <span className="badge bg-secondary ms-2">{layer.data.length}</span>
                    </span>
                    <span>
                        {map.hasLayer(layerRef) === true ?
                            <IconEye onClick={() => {
                                map.removeLayer(layerRef);
                                setFilterKey(filterKey + 1); // force render
                            }} role="button" size={20} title="Hide"/>
                            :
                            <IconEyeSlash onClick={() => {
                                map.addLayer(layerRef);
                                setFilterKey(filterKey + 1); // force render
                            }} role="button" size={20} title="Show"/>}
                        <IconFilter className="me-2 ms-2" onClick={() => {
                            setShow(!show);
                        }} role="button" size={20} title="Toggle filter"/>
                        <IconArrowBarUp onClick={() => {
                            layerRef.bringToFront();
                        }} role="button" size={20} title="Bring to front"/>
                    </span>
                </div>
                {show && <Form className="mt-3" onSubmit={event => event.preventDefault()}>
                    <Form.Group>
                        {Object.keys(formValues).map(formValueKey =>
                            <FilterLineForm
                                defaults={layer.defaults}
                                handleUpdate={values => {
                                    setFormValues({
                                        ...formValues,
                                        [formValueKey]: values,
                                    });
                                }}
                                key={formValueKey}
                                properties={layer.data.length > 0 && 'properties' in layer.data[0] ? layer.data[0].properties : []}
                                submit={formSubmit}
                            />
                        )}
                        <div className="container g-0 text-center">
                            <div className="g-2 row">
                                <div className="col-6">
                                    <DropdownButton className="d-grid" title="More" variant="outline-secondary">
                                        <Dropdown.Item  className="d-grid" onClick={() => {
                                            const lastFormValuesKey = Object.keys(formValues).map(localKey => parseInt(localKey)).pop();
                                            setFormValues({
                                                ...formValues,
                                                [lastFormValuesKey+1]: {},
                                            });
                                        }}>Add filter</Dropdown.Item>
                                        {Object.keys(formValues).length > 1 && <Dropdown.Item onClick={() => {
                                            const localFormValues = formValues;
                                            const lastFormValuesKey = Object.keys(formValues).map(localKey => parseInt(localKey)).pop();
                                            delete localFormValues[lastFormValuesKey];
                                            setFormValues({
                                               ...localFormValues,
                                            });
                                            }}>Remove filter</Dropdown.Item>}
                                        <Dropdown.Item onClick={() => {
                                            resetDataLayer();
                                        }}>Reset data</Dropdown.Item>
                                    </DropdownButton>
                                </div>
                                <div className="col-6">
                                    <div className="d-grid">
                                        <Button disabled={isLoading === true} onClick={formSubmit} variant="secondary">Filter</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>}
            </li>
        );
    }
}
