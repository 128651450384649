import { fetchWithMsal, handleResponse } from "../utils";

export const operatorService = {
  autocomplete,
  getElement,
  getElements,
  near,
  intersect,
};

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/operators`;
const baseUrlV2 = `${process.env.REACT_APP_API_BASE_URL}/v2/operators`;
const sharedParams = `&size=${process.env.REACT_APP_API_MAX_PAGE_SIZE}`;

async function autocomplete(layerId, fields, value, limit = 5) {
  const compound = {
    must: [
      {
        equals: {
          path: "layer_id",
          value: layerId,
        },
      },
    ],
    should: [],
    minimumShouldMatch: 1,
  };

  fields.forEach((field) => {
    compound.should.push({
      autocomplete: {
        path: field,
        tokenOrder: "any",
        query: value.replaceAll("-", " "),
      },
    });
  });

  return paginate(compound, limit, false);
}

async function getElement(layerId, fields = []) {
  const element = await getElements(layerId, fields, 1);
  return 0 === element.length ? null : element;
}

async function getElements(layerId, fields = [], limit = null) {
  const compound = {
    must: [
      {
        equals: {
          path: "layer_id",
          value: layerId,
        },
      },
    ],
  };

  fields.forEach((field) => {
    compound.must.push({
      equals: {
        path: field.path,
        value: field.value,
      },
    });
  });

  return paginate(compound, limit);
}

async function intersect(layerId, elementId) {
  return fetchWithMsal(
    `${baseUrlV2}/intersect/${layerId}/${elementId}?&step=${process.env.REACT_APP_API_MAX_PAGE_SIZE}`,
    {
      method: "HEAD",
    }
  )
    .then((response) => {
      let headers = Object.fromEntries(response.headers.entries());
      let proms = Object.keys(headers)
        .filter((k) => k.substring(0, 11) === "x-jsp-page-")
        .map((k) => intersectOne(layerId, elementId, headers[k]));
      proms.push(intersectOne(layerId, elementId));
      return Promise.all(proms);
    })
    .then((pages) => {
      return pages.map((page) => page.items).flat(1);
    })
    .catch((error) => {
      console.error(
        `operatorService.intersect: Layer ID: ${layerId} ; Element ID: ${elementId} ;`,
        error
      );
      return [];
    });
}

function intersectOne(layerId, elementId, searchAfter = null) {
  let url = `${baseUrlV2}/intersect/${layerId}/${elementId}?${sharedParams}`;
  if (searchAfter !== null) {
    url += `&searchAfter=${encodeURIComponent(searchAfter)}`;
  }

  return fetchWithMsal(url)
    .then(handleResponse)
    .catch((error) => {
      console.error(`operatorService.intersectOne: ${url}`, error);
      return [];
    });
}

// maxDist in meters
async function near(layerId, coordinates, maxDist = null) {
  const params = [];

  if (maxDist !== null) {
    params.push(`max_dist=${maxDist}`);
  }

  const url = `${baseUrl}/near/${layerId}/${coordinates[0]}/${
    coordinates[1]
  }?${params.join("&")}`;
  return await fetchWithMsal(url)
    .then(handleResponse)
    .catch((error) => {
      console.error(`operatorService.near: ${url}`, error);
      return [];
    });
}

// not to be exposed, paginates search
async function paginate(compound, limit, sort = true) {
  // set default page size
  const limitDefault = parseInt(process.env.REACT_APP_API_MAX_PAGE_SIZE);
  if (null === limit) {
    limit = limitDefault;
    // avoid pagination when requested page size matches default page size
  } else if (limit === limitDefault) {
    limit++;
  }

  // run search to get first response, if sort true then results are sorted by display_name
  let response = await search(compound, limit, sort);

  // no results, return empty
  if (0 === response.length) {
    return [];
  }

  // get one, return one
  if (1 === limit) {
    return response[0];
  }

  // get custom count, return them
  if (limit !== limitDefault) {
    return response;
  }

  let results = [...response];
  // loop through responses
  while (response.length === limit) {
    response = await search(
      compound,
      limit,
      sort,
      response[response.length - 1].searchAfter
    );
    results = [...results, ...response];
  }

  return results;
}

// not to be exposed, used by paginate
function search(compound, limit, sort = true, searchAfter = null) {
  let url = `${baseUrlV2}/search?sort=${sort}`;
  if (null !== limit) {
    url += `&limit=${limit}`;
  }
  if (null !== searchAfter) {
    url += `&searchAfter=${encodeURIComponent(searchAfter)}`;
  }

  return fetchWithMsal(url, {
    method: "POST",
    body: JSON.stringify({
      index_name: "layer_element_index",
      compound: compound,
    }),
  })
    .then(handleResponse)
    .catch((error) => {
      console.error("operatorService.search", url, compound, error);
      return [];
    });
}
