const LOCAL_STORAGE_KEY = 'jsp_user_profile_v2024-06-25';

// read from local storage or init
export function getUserProfile(country) {
    const userProfile = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    // read from local storage
    if (userProfile !== null) {
        return userProfile;
    }

    // init
    return {
        'layers': {
            [country]: null,
        },
        'mode': null,
    };
}

// write to local storage
export function setUserProfile(profile) {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(profile));
}
