import {configureStore} from '@reduxjs/toolkit';
import countryReducer from './countrySlice';
import layersReducer from './layersSlice';
import userReducer from './userSlice';

export default configureStore({
    'reducer': {
        'country': countryReducer,
        'layers': layersReducer,
        'user': userReducer,
    }
});
